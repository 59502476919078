import type { PropsWithChildren } from "react";
import { cn } from "../../util/cn.js";

type BaseComponentProps<T = unknown> = PropsWithChildren<
  T & { className?: string }
>;

export const Root = ({ children, className }: BaseComponentProps) => (
  <div className={cn("rounded-lg overflow-hidden border", className)}>
    {children}
  </div>
);

export const Head = ({ children, className }: BaseComponentProps) => (
  <div className={cn("border-b bg-border/60 font-bold p-2", className)}>
    {children}
  </div>
);

export const Body = ({ children, className }: BaseComponentProps) => (
  <div className={cn("bg-border/20 overflow-auto p-2", className)}>
    {children}
  </div>
);

export const Footer = ({ children, className }: BaseComponentProps) => (
  <div className={cn("border-t bg-border/60 p-2", className)}>{children}</div>
);
