import type { ZudokuConfig } from "zudoku";

const config: ZudokuConfig = {
  page: {
    logo: {
      src: {
        light:
          "https://blacklusion.com/resources/blacklusion_logo_text_black.png",
        dark: "https://blacklusion.com/resources/blacklusion_logo_text_white.png",
      },
      width: "250",
      alt: "blacklusion logo",
    },
  },
  metadata: {
    title: "OneApi Docs",
    description: "OneApi Global WAX Load Balancer",
    logo: "https://blacklusion.io/resources/blacklusion_logo_1024.png",
    favicon: "https://blacklusion.io/favicon.ico",
    generator: "Website Generator 1.0",
    applicationName: "OneApi Docs",
    referrer: "no-referrer",
    keywords: ["Wax Blockchain", "load balancer", "Api Node", "blacklusion"],
    authors: ["Blacklusion"],
    creator: "Blacklusion",
    publisher: "Blacklusion",
  },

  redirects: [
    { from: "/", to: "/docs" },
    { from: "/api", to: "/docs" },
  ],
  apis: {
    type: "file",
    input: "./openapi.yaml",
    navigationId: "docs",
  },
  sitemap: {
    siteUrl: "https://oneapi.dev",
  },
};

export default config;
