import type { SchemaObject } from "../../oas/parser/index.js";
import type { OperationListItemResult } from "./OperationList.js";
import { PlaygroundDialog } from "./playground/PlaygroundDialog.js";
import { generateSchemaExample } from "./util/generateSchemaExample.js";

export const PlaygroundDialogWrapper = ({
  server,
  servers,
  operation,
}: {
  server: string;
  servers?: string[];
  operation: OperationListItemResult;
}) => {
  const headers = operation.parameters
    ?.filter((p) => p.in === "header")
    .map((p) => ({
      name: p.name,
      defaultValue: p.examples?.find((x) => x.value)?.value ?? "",
    }));
  const queryParams = operation.parameters
    ?.filter((p) => p.in === "query")
    .sort((a, b) => (a.required && !b.required ? -1 : 1))
    .map((p) => ({
      name: p.name,
      defaultActive: p.required ?? false,
      isRequired: p.required ?? false,
    }));
  const pathParams = operation.parameters
    ?.filter((p) => p.in === "path")
    .map((p) => ({ name: p.name }));

  // Generate Body
  const firstContent = operation.requestBody?.content?.[0];
  const defaultBody =
    firstContent?.examples?.at(0)?.value ??
    (firstContent?.schema
      ? generateSchemaExample(firstContent.schema as SchemaObject)
      : "");

  return (
    <PlaygroundDialog
      server={server}
      servers={servers}
      method={operation.method}
      url={operation.path}
      headers={headers}
      queryParams={queryParams}
      pathParams={pathParams}
      defaultBody={
        !Object.keys(defaultBody ?? {}).length
          ? undefined
          : JSON.stringify(defaultBody, undefined, 8)
      }
    />
  );
};
